<template>
  <a-modal
    v-model="custDimesionVisible"
    title="维度选择"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    dialogClass="cust_dimesion_modal"
    :width="800"
  >
    <div class="cust-dimesion-container">
      <div class="left-main">
        <div class="cust-dimesion-hint">1.勾选需要显示的指标</div>
        <div class="left-main-header">
          <span class="header_btn" @click="handleCheckedAll">全选</span>
          <span>/</span>
          <span class="header_btn" @click="handleCheckedInverse">反选</span>
        </div>
        <div class="left-main-dimesion">
          <template v-for="(item, index) in allDimesion">
            <div class="checkbox_item" :key="item.key">
              <a-checkbox @change="handleChecked($event, index)" :checked="item.checked">
                {{ item.name }}
              </a-checkbox>
            </div>
          </template>
        </div>
      </div>
      <div class="cust-dimesion-right">
        <div class="cust-dimesion-hint">2. 拖动调整指标显示顺序</div>
        <m-vuedraggable v-model="dragArr" animation="350" chosen-class="chosen" class="drag_box">
          <transition-group>
            <template v-for="(e, i) in dragArr">
              <div :key="i" class="drag_item">
                <span class="el-icon-rank" />
                <span class="drag_text">{{ e.name }}</span>
                <span class="el-icon-close" @click="closeChecked(e, i)" />
              </div>
            </template>
          </transition-group>
        </m-vuedraggable>
      </div>
    </div>
  </a-modal>
</template>

<script>
// 根据一个数组的顺序排序另一个数组
export function sortList (sortList, dragArr) {
  const arr = dragArr.map((item) => {
    return item
  })
  sortList.sort((a, b) => {
    return arr.indexOf(a.key) - arr.indexOf(b.key)
  })
  return sortList
}
export default {
  data () {
    return {
      selectList: [],
      dragArr: []
    }
  },
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    groupByList: {
      default: () => ['date'],
      type: Array
    },
    allDimesion: {
      default: () => [],
      type: Array
    },
    submitBack: {
      default: () => {
        return true
      },
      type: Function
    }
  },
  created () {
    this.selectList = [...this.groupByList]
    this.selectList.forEach((item) => {
      this.allDimesion.forEach((child) => {
        if (item === child.key) {
          child.checked = true
        }
      })
    })
    const arr = this.allDimesion.filter((item) => item.checked)
    this.dragArr = sortList(arr, this.selectList)
  },
  mounted () {},
  computed: {
    custDimesionVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleCheckedInverse () {
      this.allDimesion.forEach((item) => (item.checked = !item.checked))
      this.dragArr = this.allDimesion.filter((item) => item.checked)
    },
    handleCheckedAll () {
      const checkedAll = !this.allDimesion.find((item) => !item.checked)
      this.allDimesion.forEach((item) => {
        item.checked = !checkedAll
      })
      if (checkedAll) {
        this.dragArr = []
      } else {
        this.dragArr = this.allDimesion
      }
    },

    handleCancel () {
      this.custDimesionVisible = false
    },
    handleSubmit () {
      if (!this.submitBack(this.dragArr)) return
      this.$emit('changeCustDimension', this.dragArr)
      this.custDimesionVisible = false
    },
    handleChecked (val, index) {
      this.allDimesion[index].checked = val.target.checked
      if (val.target.checked) {
        this.dragArr.push(this.allDimesion[index])
      } else {
        this.dragArr = this.dragArr.filter((item) => item.key !== this.allDimesion[index].key)
      }
    },
    closeChecked (item, i) {
      this.allDimesion.forEach((child) => {
        if (child.key === item.key) {
          child.checked = false
        }
      })
      this.dragArr = this.dragArr.filter((item, index) => index !== i)
    }
  }
}
</script>

<style lang="less" scoped>
.cust-dimesion-container {
  position: relative;
  border-radius: 3px;
  height: 300px;
  .left-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 10px;
    height: 100%;
    width: 70%;
    background: #fff;
    .left-main-header {
      height: 40px;
      display: flex;
      justify-content: flex-end;
      color: @primary-color;
      line-height: 40px;
      .header_btn {
        margin: 0 5px;
        cursor: pointer;
      }
    }
    .left-main-dimesion {
      flex: 1;
      .checkbox_item {
        width: 33%;
        display: inline-block;
        height: 45px;
        padding-left: 40px;
        line-height: 45px;
      }
    }
  }
  .cust-dimesion-right {
    margin-left: 10px;
    background-color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    width: calc(30% - 10px);
    border-radius: 5px;
    display: flex;
    height: 100%;
    flex-direction: column;
    .drag_box {
      flex: 1;
      background-color: #fff !important;
      overflow: auto;
    }

    .drag_item {
      display: flex;
      align-items: center;
      width: 92%;
      height: 35px;
      margin: 0 auto;
      background-color: #fff;
      box-shadow: 0 2px 2px 0 #dddfe6;
      margin-top: 9px;
      .el-icon-rank {
        width: 50px;
        text-align: center;
        font-size: 22px;
        cursor: move;
      }
      .drag_text {
        flex: 1;
        text-align: center;
      }
      .el-icon-close {
        font-size: 18px;
        width: 42px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .cust-dimesion-hint {
    height: 30px;
    line-height: 30px;
    padding-left: 20px;
  }
}
</style>

<style lang="less">
.cust_dimesion_modal .ant-modal-body {
  background-color: #eee;
  .search_template_wrapper {
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
  }
}
</style>
